import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@mui/material/Icon';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { usePages } from './PagesContext';

const useStyles = makeStyles((theme) => ({
    menuText: {
      flex: 1,
      flexWrap: 'wrap',
    },
    list: {
      flexGrow: 1,
    },
    menuIcon: {
      minWidth: '50px',
      marginLeft: theme.spacing(0.6),
    },
    inset: {
      marginLeft: theme.spacing(2),
    },
    admin: {
      flex: 1,
      justifyContent: 'flex-end'
    },  
  }));

export default function PageMenu({ page, highlight, handleSelectedListItem, open
  }) {
    const classes = useStyles();
    const { changeCurrentPage } = usePages();
    const expandable = page.children && page.children?.length > 0;
    const [openCollapse, setOpenCollapse] = useState(false);
    const currentPath = window.location.pathname;
  
    const handleOpenSubMenu = () => {
      setOpenCollapse(!openCollapse);
    };
  
    const ParentMenuItem = (<>
     { page.path ? (
      <ListItemButton
          dense={page.depth > 1}
          selected={highlight === page.pageUid}
          component={Link}
          to={page.path || currentPath}
          onClick={() => {
            changeCurrentPage(page.pageUid);
            handleSelectedListItem(page.pageUid);
          }}
          key={`nav-item-${page.pageUid}`}
        >
          { page.iconName
            ? (
              <>
                <ListItemIcon className={classes.menuIcon}>
                  <Icon>{page.iconName}</Icon>
                </ListItemIcon>
                <ListItemText
                  component={Typography}
                  className={classes.menuText}
                  primary={page.pageName}
                />
              </>
            ) : (
              <ListItemText
                inset
                className={page.depth > 1 ? classes.inset : classes.menuText}
                secondary={page.pageName}
              />
            )}
          { expandable && openCollapse === true && <ExpandLessRounded onClick={handleOpenSubMenu} /> }
          { expandable && openCollapse === false && <ExpandMoreRounded onClick={handleOpenSubMenu} /> }
        </ListItemButton>) : (
          <ListItemButton
          dense={page.depth > 1}
          selected={highlight === page.pageUid}
          key={`nav-item-${page.pageUid}`}
          onClick={() => {
            handleOpenSubMenu();
          }}

        >
          { page.iconName
            ? (
              <>
                <ListItemIcon className={classes.menuIcon}>
                  <Icon>{page.iconName}</Icon>
                </ListItemIcon>
                <ListItemText
                  component={Typography}
                  className={classes.menuText}
                  primary={page.pageName}
                />
              </>
            ) : (
              <ListItemText
                inset
                className={page.depth > 1 ? classes.inset : classes.menuText}
                secondary={page.pageName}
              />
            )}
          { expandable && openCollapse === true && <ExpandLessRounded onClick={handleOpenSubMenu} /> }
          { expandable && openCollapse === false && <ExpandMoreRounded onClick={handleOpenSubMenu} /> }
        </ListItemButton>
          
        )}

    </>);
  
    const ChildMenuItem = expandable ? (
      <Collapse in={openCollapse && open} timeout="auto" unmountOnExit>
        <Divider />
        <List component="div" disablePadding>
          {page.children.map((sub) => (
            <PageMenu
              page={sub}
              highlight={highlight}
              handleSelectedListItem={handleSelectedListItem}
              open={open}
              key={sub.pageUid}
            />
          ))}
        </List>
      </Collapse>
    ) : null;
  
    return (
      <React.Fragment key={`nav-div-${page.pageUid}`}>
        {ParentMenuItem}
        {ChildMenuItem}
      </React.Fragment>
    );
  }